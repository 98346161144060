import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

export const COLORS = {
  PRIMARY_MAIN: "#0E5AA7",
  DARK_GOLD_ORANGE: "#FF6240",
  GOLDEN_BELL: "#E3870F",
  PERSIAN_GREEN: "#009688",
  BLACK: "#000000",
  MAROON: "#843D77",
  SEA_GREEN: "#319E60",
  MINSK: "#7F7FBB",
  DARK_RED: "#F34041",
  OCEAN_GREEN: "#3ABA71",
  LIGHT_PERSIAN_GREEN: "#59BAB1",
  ALERT_ORANGE: "#F3BA40",
  MINSK_DARK: "#3B3B98",
  BLUE_STONE: "#006158",
  AMBER: "#E79832",
  PRIMARY_LIGHT: "#92B4D7",
  SEA_GREEN2: "#267949",
  ASTRAL: "#3172B4",
  INFO_GREEN: "#7FD2A3",
  PIMPKIN_SKIN: "#C1720C",
  DUSTY_GRAY_LIGHT: "#969696",
  INFO_BLUE: "#20B0E6",
  PRIMARY_LIGHT_BLUE: "#6293C5",
  GRADIENT_BLUE: "#91AECC",
  ALIZARIN_CRIMSON: "#DD2326",
  SUCCESS_GREEN: "#FFDF28",
  TAG_YEllOW: "#D9BD22",
  ERROR_RED: "#F45C5D",
  LIGHT_GREEN: "#63C98E",
  PICTON_BLUE: "#41BCEA",
  CREAM_CAN: "#F5C45D",
  VISTA_BLUE: "#7FD2A3",
  TARA: "#CEEEDB",
  AVATAR_OCEAN: "#00EEDB",
  PALE_GREEN: "#83DEA5",
  CORAL_PINK: "#F37172",
  OLD_GOLD: "#CF9E36",
  STATUS_YELLOW: "#F79E1B",
  MINSK_LIGHT: "#A6A6D0",
  SCAMPI: "#5858A7",
  PRIMARY_DARK: "#0E5A80",
  INFO_BLACK: "#35363A",
  PALE_PURPLE: "#A2A3F5",
  AQUA_BLUE: "#63C8EE",
  VIBRANT_YELLOW: "#FFE553",
  VENETIAN_RED: "#F2A57F",
  BRICK_RED: "#ED6F6A",
  VERY_LIGHT_GREY: "#C9C9C9",
  SILVER_CHALICE: "#B2B2B2",
  FOUNTAIN_BLUE: "#4DB6AC",
  ALICE_BLUE: "#3E7BB9",
  PALE_RED: "#F78283",
  GREEN_BLUE: "#157296",
  GOLDEN_YELLOW_LIGHT: "#F2C892",
  AZURE_BLUE: "#0A437C",
  PERSIAN_GREEN_LIGHT: "#8CCFC9",
  PERSIAN_GREEN_DARK: "#007F73",
  READY_BLUE: "#0B4C8E",
  LUCKY: "#A6911A",
  DUSTY_GRAY: "#979797",
  JUNGLE_GREEN: "#26A599",
  PARTIAL_SUCCESS_GREEN: "#58C486",
  SECONDARY_MAIN: "#F5F5F5",
  SECONDARY_LIGHT: "#EEEEEE",
  SECONDARY_DARK: "#E6E6E6",
  LIGHT_GREY: "#ECF0F4",
  BASE_RED: "#FDF4F4",
  TEXT_PRIMARY: "#252525",
  BOX_SHADOW_PRIMARY: "rgba(14, 90, 167, 0.2)",
  TRANSPARENT_BLUE: "rgba(10, 67, 124, 0.05)",
  TRANSPARENT_BLUE_BORDER: "rgba(10, 67, 124, 0.2)",
  WHITE: "#FFFFFF",
  BLACK_SHADOW: "rgba(0,0,0,0.05)",
  LIGHT_BLACK_SHADOW: "rgba(0, 0, 0, 0.15)",
  BLACK_INPUT_BORDER: "rgba(0, 0, 0, 0.23)",
  BLACK_INPUT_LABEL: "rgba(0, 0, 0, 0.54)",
  GRAY_DARK: "#B2B2B2",
  ALTO: "#DCDCDC",
  LIGHT_RED: "#F79C9D",
  DOVE_GRAY: "#666666",
  DOVE_GRAY_LIGHT: "rgba(102,102,102,0.6)",
  SILVER: "#C4C4C4",
  COPY: "#4D4D4D",
  TRANSPARENT: "transparent",
  LOADER_BACKGROUND: "rgba(255, 255, 255, 0.38)",
  TABLE_ROW_SELECTED: "#F3F6F8",
  SUB_HEADER_SELECTED: "#E9EFF2",
  MINE_SHAFT: "#2D2D2D",
  ROUTE_CONTAINER_BACKGROUND: "#FAFAFA",
  FOOTER_COUNT_BACKGROUND: "rgba(37,37,37, 0.85)",
  MINE_SHAFT_LIGHT: "#F4F4F4",
  MADISON_LIGHT: "#F2F5F8",
  TROPICAL_BLUE: "#C7EBF9",
  CATSKILL_WHITE: "#DFE8F2",
  BLUE_HAZE: "#CDCDE5",
  BARLEY_WHITE: "#FFF6C9",
  DOUBLE_PEARL_LUSTA: "#FCEECF",
  MYSTIC_GREY: "#D2D2D2",
  GREY_WHITE: "#FCFCFC",
  SECONDARY_MID_DARK: "rgba(230, 230, 230, 0.24)",
  PALE_GRAY: "#C3C3C3",
  GREY_SCALE: "#747474",
  DRIFT_WOOD: "#B48A53",
  SILVER_CHALICE2: "#A0A0A0",
  PICTON_BLUE_LIGHT: "rgba(65, 188, 234, 0.3)",
  CREAM_CAN_LIGHT: "rgba(245, 196, 93, 0.3)",
  VISTA_BLUE_LIGHT: "rgba(127, 210, 163, 0.3)",
  SEA_GREEN_LIGHT: "rgba(49, 158, 96, 0.3)",
  LUCKY_LIGHT: "rgba(166, 145, 26, 0.3)",
  JUNGLE_GREEN_LIGHT: "rgba(38, 165, 153, 0.3)",
  BLACK_LIGHT: "rgba(0, 0, 0, 0.3)",
  SCAMPI_LIST: "rgba(88, 88, 167, 0.3)",
  GOLDEN_BELL_LIGHT: "rgba(227, 135, 15, 0.3)",
  DUSTY_GRAY_NEW_LIGHT: "rgba(151, 151, 151, 0.3)",
  SEA_GREEN2_LIGHT: "rgba(38, 121, 73, 0.3)",
  BLUE_HAZE_LIGHT: "rgba(205, 205, 229, 0.3)",
  SCAMPI_LIGHT: "rgba(88, 88, 167, 0.3)",
  TROPICAL_BLUE_LIGHT: "rgba(199, 235, 249, 0.3)",
  OLD_GOLD_LIGHT: "rgba(207, 158, 54, 0.3)",
  DOVE_GREY: "rgba(102, 102, 102, 0.6)",
  MINE_SHAFT_NEW: "#1F1F1F",
  EMPEROR: "#505050",
  COD_GRAY: "#141414",
  ALICE_GRAY: "#F7F8F9",
  BLACK_SQUEEZE: "#F3F7FB",
  ATHENS_GRAY: "#FAFAFB",
  CATSKILL_WHITE_LIGHT: "#F0F3F7",
  JAGGED_ICE: "#BFE4E1",
  LIGHT_BLUE:'rgba(14, 90, 167, 0.05)',
  LIGHT_WHITE: "rgba(0, 0, 0, 0.06)",
};

const checkboxDefaultIcon =
  "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgcng9IjIuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0IyQjJCMiIvPgo8L3N2Zz4K)";
const checkboxCheckedIcon =
  "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgcng9IjIuNSIgZmlsbD0iIzBFNUFBNyIgZmlsbC1vcGFjaXR5PSIwLjEiIHN0cm9rZT0iIzBFNUFBNyIvPgo8cGF0aCBkPSJNNiAxMS44NTVMMTAuMDk2IDE2TDE4IDcuNzg3TDE3LjI0MyA3TDEwLjA4NSAxNC40MzdMNi43NDcgMTEuMDU3TDYgMTEuODU1WiIgZmlsbD0iIzBFNUFBNyIvPgo8L3N2Zz4K)";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat", "Roboto", "Helvetica", "sans-serif"].join(","),
    fontWeightRegular: 500,
  },
  palette: {
    type: "light",
    primary: {
      main: COLORS.PRIMARY_MAIN,
      light: COLORS.PRIMARY_LIGHT,
      dark: COLORS.PRIMARY_DARK,
    },
    secondary: {
      main: COLORS.SECONDARY_MAIN,
      light: COLORS.SECONDARY_LIGHT,
      dark: COLORS.SECONDARY_DARK,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: COLORS.WHITE,
    },
    text: {
      primary: COLORS.TEXT_PRIMARY,
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        lineHeight: 1,
      },
    },
    MuiTabs: {
      root: {
        flex: "auto",
        minHeight: 56,
        paddingLeft: 24,
        paddingTop: 16,
      },
      indicator: {
        backgroundColor: COLORS.PRIMARY_MAIN,
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
        fontWeight: 600,
        minHeight: 24,
        marginRight: 24,
        padding: "6px 0px 2px",
        minWidth: "auto !important",
        textTransform: "none",
        "&$selected": {
          color: COLORS.PRIMARY_MAIN,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        minWidth: 200,
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: COLORS.BLACK_INPUT_BORDER,
          borderWidth: 1,
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.BLACK_INPUT_BORDER,
          },
        },
        "&.hasLabel": {
          "& .MuiSelect-root": {
            paddingTop: 16,
            paddingBottom: 5,
          },
        },
      },
      adornedEnd: {
        paddingRight: 8,
        "& .MuiSvgIcon-root": {
          padding: 4,
        },
      },
      input: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
        fontSize: 12,
        height: "auto",
      },
    },
    MuiSelect: {
      root: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
        fontWeight: 600,
      },
      icon: {
        padding: 4,
      },
      select: {
        "&:focus": {
          backgroundColor: "inherit",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        marginTop: 13,
        fontWeight: 400,
        fontSize: 12,
        "&.Mui-focused": {
          color: COLORS.BLACK_INPUT_LABEL,
        },
      },
    },
    MuiButton: {
      root: {
        padding: "7px 16px",
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 4,
        textTransform: "none",
        "&.primary": {
          backgroundColor: COLORS.PRIMARY_MAIN,
          color: COLORS.WHITE,
          border: `1px solid ${COLORS.TRANSPARENT}`,
          "&$disabled": {
            backgroundColor: COLORS.PRIMARY_LIGHT,
            color: COLORS.WHITE,
          },
          "&:hover": {
            backgroundColor: COLORS.PRIMARY_MAIN,
          },
        },
        "&.secondary": {
          backgroundColor: COLORS.WHITE,
          color: COLORS.PRIMARY_MAIN,
          border: `1px solid ${COLORS.PRIMARY_MAIN}`,
          "&$disabled": {
            color: COLORS.PRIMARY_MAIN,
          },
          "&:hover": {
            backgroundColor: COLORS.WHITE,
          },
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
    MuiAccordion: {
      root: {
        width: "100%",
        borderRadius: "8px !important",
        boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.20)",
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 64,
        borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
        "&$expanded": {
          minHeight: 64,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 16,
      },
    },
    MuiListItemText: {
      root: {
        margin: "0 !important",
      },
      primary: {
        fontSize: 12,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        borderRadius: 0,
        background: checkboxDefaultIcon,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "16px 16px",
        width: 16,
        height: 16,
        "& svg path": {
          display: "none",
        },
      },
      colorPrimary: {
        "&.Mui-checked": {
          backgroundImage: checkboxCheckedIcon,
        },
      },
      indeterminate: {
        backgroundImage: `${checkboxDefaultIcon} !important`,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 12,
        padding: "14px 10px 13px",
        borderBottom: "none",
        /* '&:first-child': {
          paddingLeft: 24
        } */
      },
      head: {
        lineHeight: "20px",
        fontWeight: 500,
        borderTop: "none",
        color: "#1F1F1F",
      },
      body: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      stickyHeader: {
        backgroundColor: COLORS.MINE_SHAFT_LIGHT,
      },
    },
    MuiTableRow: {
      root: {
        "&.Mui-selected,&.Mui-selected:hover,&:hover": {
          backgroundColor: "rgba(198, 213, 229, 0.4)",
        },
      },
      head: {
        backgroundColor: COLORS.WHITE,
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover,&:focus": {
          color: "inherit",
        },
      },
    },
    MuiTooltip: {
      popper: {
        fontSize: 10,
        "& .tooltipItem": {
          fontSize: 10,
          paddingBottom: 4,
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      },
      tooltipPlacementBottom: {
        margin: "0px",
        "@media (min-width: 600px)": {
          margin: "0px",
        },
      },
    },
    MuiToolbar: {
      gutters: {
        padding: "0px 16px !important",
      },
    },
    MuiAppBar: {
      root: {},
      colorPrimary: {
        backgroundColor: COLORS.WHITE,
        color: COLORS.TEXT_PRIMARY,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 680,
      },
    },
  },
});

export default theme;
